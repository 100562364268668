.home-navbar {
  width: 100%;
  height: 773px;
  display: flex;
  background: url('/public/img/pizza.jpg');
  background-size: cover;
}

.home-navbar .navbar-container {
  margin-top: 55px;
  height: 642px;
  width: 1191px;
}

.home-navbar .navbar-container .menu {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-navbar .navbar-container .menu .brand-logo {
  width: 125px;
  /* height: 60px; */
}

.home-navbar .navbar-container .menu .links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 700px;
  align-items: center;
}

.home-navbar .navbar-container .menu .links .login-button {
    background: #3776CC;
    color: #f8f8ff
}

.home-navbar .navbar-container .menu .user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 24px;
}

.home-navbar a {
  text-decoration: none;
}

.home-navbar .hoover-line a {
  color: #f8f8ff;
  z-index: 100;
}

.home-navbar .underline {
  display: block;
  content: "";
  border-bottom: solid 2px #d7b686;
}

.home-navbar .hoover-line::after {
  display: block;
  content: '';
  border-bottom: solid 2px #d7b686;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.home-navbar .hoover-line:hover:after {
  transform: scaleX(1);
}

.home-navbar div.hoover-line::after {
  transform-origin: 0% 50%;
}

.home-navbar .navbar-container .header-frame {
  margin-top: 142px;
  height: 462px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-navbar .navbar-container .header-frame .detail {
  display: flex;
  flex-direction: column;
  width: 510px;
}

.home-navbar .navbar-container .header-frame .head-main-txt {
  width: 450px;
  height: 150px; 
  font-family: Roboto Serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: -1.5%;
  color: rgb(248, 248, 255);

}

.home-navbar .navbar-container .header-frame .welcome-txt {
 width: 510px;
 height: 42px;
 font-family: Roboto Serif;
 font-size: 36px;
 font-weight: 400;
 line-height: 42px;
 letter-spacing: -1.5%;
 text-align: left;
 color: rgb(215, 182, 134);
}

.home-navbar .navbar-container .header-frame .service-txt {
 margin-top: 23px;
 width: 153px;
 height: 23px;
 font-family: Roboto Serif;
 font-size: 20px;
 font-weight: 400;
 line-height: 23px;
 letter-spacing: -1.5%;
 text-align: left;
 color: rgb(213, 213, 213);
}

.home-navbar .navbar-container .detail .signup {
  margin-top: 73px;
}


.home-navbar .navbar-container .detail .signup-button {
  width: 209px;
  height: 60px;
  border-radius: 4px;
  background: rgb(25, 118, 210);
  color: rgb(248, 248, 255);
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: center;
}

.home-navbar .navbar-container .logo-frame {
  margin-top: 43px;
  width: 690px;
  height: 419px;
}

.home-navbar .navbar-container .logo-image {
  width: 100%;
  height: 100%;
  /* background: url('/public/img/logo_middle.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(0.1);

  
}




/* BASKET */

.basket-frame {
  position: relative;
  padding: 15px;
  width: 450px;
  display: flex;
  flex-direction: column;
  background: #f8f8ff;
  border-radius: 16px;
}

.basket-frame .all-check-box {
  padding: 0px 6px;
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #343434;
  justify-content: right;
}

.basket-frame .basket-info-box {
  position: relative;
  margin-top: 6px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.basket-frame .basket-info-box .info-wrapper {

  display: flex;
  flex-direction: row;
  align-items: center;
}

.basket-info-box .product-img {
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 29px;
}

.basket-info-box .product-name {
  margin-left: 15px;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.basket-info-box .product-price {
  width: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ae0000;
  margin-left: 15px;

}

.basket-frame .basket-info-box .button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;

}
.basket-frame .basket-info-box .button-wrapper .col-2 {
  display: flex;
  flex-direction: row;
  color: #f44336;
  margin: 0 13px 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 10px;

}
.basket-frame .basket-info-box .button-wrapper .col-2 button {
  display: flex;
  flex-direction: row;
  background-color: #f44336;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  border: none;
  color: #f1f1f1;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

}
.basket-info-box
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px;
}

.basket-info-box .css-ece9u5 {
  min-width: 72px;
}

.basket-frame .cancel-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.basket-frame .basket-order {
  margin-top: 10px;
  padding-right:10px;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}
.basket-frame .basket-order .delivery-fee {
  display: flex;
  padding: 10px 0;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;

}
.basket-frame .basket-order .free-delivery {
  display: flex;
  padding: 10px 0;
  gap: 10px;
}
.basket-frame .basket-order .free-delivery span {
  color: rgb(5, 174, 22);
}

.basket-order .price {
  display: flex;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.basket-order .total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color:rgb(5, 174, 22);
  margin-bottom: 15px;
}

.basket-frame .orders-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.basket-frame .orders-wrapper::-webkit-scrollbar {
  width: 10px;
}

.basket-frame .orders-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.basket-frame .orders-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.basket-frame .orders-main-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
}


.other-navbar {
  width: 100%;
  height: 773px;
  display: flex;
  background: url('/public/img/pizza.jpg');
  background-size: cover;
}

.other-navbar .navbar-container {
  margin-top: 55px;
}

.other-navbar .navbar-container .menu {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.other-navbar .navbar-container .menu .brand-logo {
  width: 125px;
  /* height: 30px; */
}

.other-navbar .navbar-container .menu .links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 700px;
  align-items: center;
}

.other-navbar .navbar-container .menu .links .login-button {
    background: #3776CC;
    color: #f8f8ff
}

.other-navbar .navbar-container .menu .user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 24px;
}

.other-navbar a {
  text-decoration: none;
}

.other-navbar .hoover-line a {
  color: #f8f8ff;
  z-index: 100;
}

.other-navbar .underline {
  display: block;
  content: "";
  border-bottom: solid 2px #d7b686;
}

.other-navbar .hoover-line::after {
  display: block;
  content: '';
  border-bottom: solid 2px #d7b686;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.other-navbar .hoover-line:hover:after {
  transform: scaleX(1);
}

.other-navbar div.hoover-line::after {
  transform-origin: 0% 50%;
}
