.help-page {
  width: 100%;
  height: auto;
  display: flex;
  background: #f8f8ff;
}

.help-page .help-container {
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: row;
}

.help-page .help-menu {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #f8f8ff;
}

.help-page .help-menu .table-list {
  display: flex;
  justify-content: space-between;
}

.help-page .css-1gsv261 {
  width: 75%;
}

.help-page .css-heg063-MuiTabs-flexContainer {
  justify-content: space-between;
}

.help-page .css-19kzrtu {
  padding-top: 24px;
}

.help-page .help-main-content {
  width: 100%;
  height: 1000px;
}

.help-page .rules-box {
  padding: 24px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f8ff;
  border: 1px solid #ededed;
  box-shadow: 0px 20px 30px rgba(25, 25, 46, 0.04);
  border-radius: 8px;
}

.rules-box .rules-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rules-box .rules-frame p {
  border-bottom: 1px solid #dddddd;
  color: #000000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.00938em;
}

.help-page .accordion-menu {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.help-page .admin-letter-box {
  padding: 60px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f8ff;
  border: 1px solid #ededed;
  box-shadow: 0px 20px 30px rgba(25, 25, 46, 0.04);
  border-radius: 8px;
}

.help-page .admin-letter-box .admin-letter-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.help-page .admin-letter-container .admin-letter-frame {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.help-page .admin-letter-frame span {
  font-family: "ABeeZee";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #140342;
}

.help-page .admin-letter-frame p {
  margin-top: 20px;
  font-family: "Sedan";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #4f547b;
}

.help-page .admin-letter-container .admin-letter-frame {
  display: flex;
  flex-direction: column;
}

.help-page .admin-letter-frame .admin-input-box {
  margin-top: 30px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.help-page .admin-letter-frame .admin-input-box label {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #140342;
}

.help-page .admin-letter-frame .admin-input-box input {
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  background: #f8f8ff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #4f547b;
}

.help-page .admin-letter-frame .admin-input-box textarea {
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  height: 150px;
  display: flex;
  background: #f8f8ff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #4f547b;
}
