@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.products-page {
  position: relative;
  width: 100%;
  height: auto;
  background: #f8f8ff;
}
.products-page .products {
 
   width: 100%;
   height: auto;
   display: flex;
   background: #f8f8ff;
   display: flex;
   flex-direction: column;

}

.products-page .products .MuiContainer-root{
  padding: 0;
}

.products-page .products .avatar-big-box {
  position: relative;
  width: 100%;
  height: 46px; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 77px 0 20px 0;
}

.products-page .products .avatar-big-box .title {
  color: rgb(52, 52, 52);
font-family: Roboto Serif;
font-size: 36px;
font-weight: 600;
line-height: 42px;
letter-spacing: 0%;
text-align: left;
}

.products-page .products .search-wrapper {
  display: flex;
  flex-direction: row;
}

.products-page .products .input-wrapper {
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 360px;
 height: 36px;
 margin: 40px;
 box-sizing: border-box;
 border: 1px solid rgb(255, 255, 255);
 border-radius: 18.5px;
 box-shadow: 0px 4px 4px 0px rgba(213, 213, 213, 0.25);
 background: rgb(255, 252, 252);
 border: 1px;
  border: 1px solid #d7b586; /* Softened border color */

}

.products-page .products  .text-field {
 height: 40px;
  flex: 1;
 margin-left: 17px;
  color: rgb(74, 74, 74);
 font-family: Quicksand;
 font-size: 15px;
 font-weight: 400; 
 line-height: 19px;
 letter-spacing: 0%;
 text-align: left;
}

.products-page .products  .icon-btn {
  width: 106px;
  height: 40px;
  border-radius: 18.5px;
  background: rgb(52, 52, 52);
  color: rgb(253, 252, 251);
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
  text-transform:uppercase;
  gap: 8px;
}

.products-page .products .dishes-filter-section {
  position: relative;
  display: flex;
}


.products-page .products .dishes-filter-box {
  display: flex;
  flex-direction: row;
  margin: 40px;
}
.products-page .products .dishes-filter-box  .filter{
  font-size: 15px;
  font-weight: 700;
  text-transform:uppercase;
  justify-content: center;
  margin-right: 15px;

}

.products-page .products .dishes-filter-box .order{
  width: 70px;
  height: 36px;
  border-radius: 0px;
}
.products-page .products .list-category-section {
  display: flex;
  margin-left: 35px;

}
.products-page .products .list-category-section .menu{
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;


}
.products-page .products .list-category-section .product-category {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  margin: 20px auto;
}

.products-page .products .list-category-section .product-category .order {
 font-size: 14px;
 font-weight: 500;
 line-height: 16px;
 letter-spacing: 0%;
 text-align: left;
 min-width: 100px;
 padding: 11px 0;
  font-family: Roboto;
  border-radius: 0px;

}

.products-page .products .product-wrapper {
  width: 1252px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.products-page .products .product-wrapper .sold-out-overlay {
 position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
  border-radius: 50px;
}

.products-page .products .product-wrapper .no-data{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}


.products-page .products .product-wrapper .product-desc .product-title{
 font-family: 'Poppins';
 font-style: normal;
 font-weight: 600;
 font-size: 20px;
 line-height: 30px;
}
.products-page .products .product-wrapper .product-desc .product-ingridients{
 margin-top: 10px;
 font-family: 'Italic';
 font-style: normal;
 font-weight: 500;
 font-size: 15px;
 line-height: 30px;
 padding: 0 5px 0 5px;
 height: 80px;
}
.products-page .products .product-wrapper .product-desc .product-ingridients .ingridients{

  color: #ff9200;
}

.products-page .products .product-wrapper .product-img {
  position: relative;
  width: 253px;
  height: 255px;
  background-size: cover;
  border-radius: 50px;
  margin-top: 20px;
}


.products-page .products .product-wrapper .product-card{
  margin-top: 40px;
  margin-right: 20px;
  width: 293px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(235, 226, 226);
  border-radius: 50px;
}


.products-page .products .product-wrapper .product-img .product-sale{
  margin: 20px;
  width: 90px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9200;
  box-shadow: 4px 6px 20px #0000001a;
  border-radius: 25px;
  font-family: "Poppins";
  font-size: 11px;
  color: #f8f8ff;
}

.products-page .products .product-wrapper .product-desc {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}


.products-page .products .product-wrapper .product-desc .product-price{
  margin-top: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.products-page .products .product-wrapper .product-img::before{
  border-radius: 50px;
  content: '';
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0,0,0,0.5);
  z-index: 2;
}

/* .products-page .products .product-wrapper .product-img:hover::before{
  border-radius: 0px 50px 0px 0px;
  opacity: 1;
} */

.products-page .products .product-wrapper .product-img img{
  position: absolute;
  display: block;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.products-page .products .product-wrapper .shop-btn{
  padding: 20px;
  margin: 15px 0;
  width: 34px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease;
  background: #ff9200;
  border: 2px solid #d7b686;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #f8f8ff;
}

/* .products-page .products .product-wrapper .product-img:hover .shop-btn{
 opacity: 0;
} */

.products-page .products .product-wrapper .product-img:hover .view-btn{
  opacity: 1;
}

.products-page .products .product-wrapper .view-btn{
  position: absolute;
  top: 200px;
  padding: 5px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 110px;
  cursor: pointer;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  color: #fdf1ec;
}

.products-page .products .pagination-section{
  position: relative;
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.products-page .products .brands-logo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(13, 21, 24);
  width: 100%;
  height: auto;
  margin: 53px 0 80px 0;
}

.products-page .products .brands-logo .brand-title{
color: rgb(227, 192, 141);
font-family: Roboto;
font-size: 36px;
font-weight: 700;
line-height: 42px;
letter-spacing: 0%;
text-align: left;
margin: 84px 0 54px 0;
}


.products-page .products .brands-logo .image-container{
  width: 1293px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: rgb(13, 21, 24);
  margin-bottom: 91px;
}

.products-page .products .brands-logo .image-wrapper{
  width: 238.87px;
  height: 330px;
  border-radius: 20px;

box-shadow: 0px 0px 22px 0px rgb(228, 212, 212);
background: rgb(13, 21, 24);
}

.products-page .products .address{
  width: 100%;
}

.products-page .products .address .address-area{
  width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products-page .products .address .title{
 color: rgb(52, 52, 52);
font-family: Roboto Serif;
font-size: 36px;
font-weight: 600;
line-height: 42px;
letter-spacing: 0%;
text-align: left;
margin-bottom: 80px;
}

.products-page .products .address .address-area iframe{
  width: 1300px;
  height: 567px;
  margin-bottom: 60px;
}


/** ChosenProduct **/



.products-page .chosen-product {
  position: relative;
  width: 100%;
  height: auto;
  background:  #fdf1ec;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-page .chosen-product .product-container {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 150px;
  width: 800px;
}

.products-page .chosen-product .chosen-product-slider {
  width: 400px;
  height: 480px;
  background: #ffffff;
  box-shadow: 9px 13px 14px 5px rgba(0, 0, 0, 0.25),
    inset -1px 2px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  overflow: hidden;
}

.products-page .chosen-product .chosen-product-slider .swiper-area {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  width: 100%;
  height: 1020px;
}

.products-page
  .chosen-product
  .chosen-product-slider
  .swiper-area
  .slider-image {
  width: 100%;
  height: 100%;
}

.products-page .chosen-product .chosen-product-info {
  padding: 30px 40px;
  width: 49%;
  height: 480px;
  display: flex;
  background: #ffffff;
  box-shadow: 9px 13px 14px 5px rgba(0, 0, 0, 0.25),
    inset -1px 2px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}

.products-page .chosen-product .chosen-product-info .info-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.products-page .chosen-product .info-box .product-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.products-page .chosen-product .info-box .resto-name {
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 15px;
  color: #979797;
}

.products-page .chosen-product .info-box .rating-box {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.products-page .chosen-product .rating-box .star-box {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.products-page .chosen-product .rating-box .evaluation-box {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.products-page .chosen-product .rating-box .evaluation-box .product-view {
  display: flex;
  align-items: center;
}

.products-page .chosen-product .info-box .product-desc {
  height: 112px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #8d8d8d;
}

.products-page .chosen-product .info-box .product-price {
  margin-top: 20px;
  margin-bottom: 20px;
  /* width: 100%; */
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.products-page .chosen-product .product-price span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 22px;
  color: #000000;
  width: 100%;
}

.products-page .chosen-product .button-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-page .chosen-product button {
  width: 500px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #f8f8ff;
  border-radius: 20px;
  padding: 10px 1px !important;
}

.products-page .chosen-product .title {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin-top: 40px;
}
