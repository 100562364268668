.order-page {
  width: 100%;
  height: auto;
  background: #f8f8ff;
  display: flex;
}

.order-page .order-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}

.order-page .order-left {
  width: 860px;
  height: auto;
}

.order-page .order-right {
  width: 360px;
  height: auto;
  margin-top: 86px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.order-page .order-right .order-info-box {
  width: 360px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8ff;
  box-shadow: 15px 14px 4px rgba(0, 0, 0, 0.25),
    inset -1px 0px 16px 13px rgb(24 23 112 / 20%);
  border-radius: 16px;
  margin-bottom: 25px;
}

.order-page .order-right .order-info-box .member-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-page .order-right .order-info-box .liner {
  width: 100%;
  border: 1px solid #a1a1a1;
  margin-top: 40px;
  margin-bottom: 8px;
}

.order-page .order-info-box .order-user-img {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-page .order-info-box .order-user-img .order-user-avatar {
  width: 117px;
  height: 112px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 37px;
}

.order-page .order-info-box .order-user-icon-box {
  position: absolute;
  padding: 5px;
  width: 30px;
  height: 30px;
  top: 81%;
  right: 13px;
  display: flex;
  background: rgba(0, 0, 0, 0.33);
  border-radius: 37px;
}

.order-page .order-user-icon-box .order-user-prof-img {
  filter: brightness(0) invert(1);
}

.order-page .order-info-box .order-user-name {
  margin-top: 10px;
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #08090d;
}

.order-page .order-info-box .order-user-prof {
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #a1a1a1;
}

.order-page .order-info-box .order-user-address {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.order-page .order-info-box .spec-address-txt {
  margin-left: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616164;
}

.order-page .order-info-box .card-input {
  margin-top: 10px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #434141;
}
.order-page .order-info-box .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5px;
}
.order-page .order-info-box .card-half-input {
  margin-top: 10px;
  width: 49%;
  background: #f5f5f5;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #434141;
}

.order-page .order-info-box .cards-box {
  margin-top: 35px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-page .order-info-box .css-wca78v-MuiButtonBase-root-MuiButton-root {
  min-width: 160px;
}

.order-page .order-left .order-nav-frame {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #f8f8ff;
}

.order-page .order-left .order-nav-frame .table-list {
  display: flex;
  justify-content: space-between;
}

.order-page .css-1gsv261 {
  width: 75%;
}

.order-page .css-heg063-MuiTabs-flexContainer {
  justify-content: space-between;
}

.order-page .order-main-content {
  width: 855px;
  height: auto;
}

.order-page .css-19kzrtu {
  padding-top: 24px;
}

.order-page .order-main-content .order-main-box {
  position: relative;
  width: 100%;
  max-height: 285px;
  margin-bottom: 25px;
  background: #f8f8ff;
  box-shadow: -13px 16px 4px rgba(0, 0, 0, 0.25),
    inset 0px -3px 20px 11px #18177033;
  border-radius: 16px;
}

.order-page .order-main-content .order-main-box .order-box-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 105px;
  max-height: 205px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: scroll;
}

.order-page
  .order-main-content
  .order-main-box
  .order-box-scroll::-webkit-scrollbar {
  width: 7px;
}

.order-page
  .order-main-content
  .order-main-box
  .order-box-scroll::-webkit-scrollbar-thumb {
  background-color: #8989db;
  border-radius: 5px;
}

.order-page .order-box-scroll .orders-name-price {
  position: relative;
  height: 47px;
  margin-top: 5px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-page .order-box-scroll .orders-name-price .order-dish-img {
  position: relative;
  width: 50px;
  height: 47px;
  border-radius: 29px;
  background-size: cover;
}

.order-page .order-box-scroll .orders-name-price .title-dish {
  position: relative;
  width: 260px;
  height: 36px;
  margin-left: 20px;
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #000000;
}

.order-page .order-box-scroll .orders-name-price .price-box {
  position: relative;
  max-width: 170px;
  height: 100%;
  margin-left: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-page .order-box-scroll .orders-name-price .price-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #262626;
}

.order-page .order-main-content .order-main-box .total-price-box {
  position: relative;
  width: 100%;
  height: 41px;
  margin: 7px 0 7px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
}

.order-page .order-main-content .total-price-box .cancel-button {
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 40px;
}

.order-page .order-main-content .total-price-box .pay-button {
  background: #56b74d;
  color: #f8f8ff;
  border-radius: 10px;
  max-height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.order-page .order-main-content .total-price-box .verify-button {
  background: #0288d1;
  color: #f8f8ff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.order-page .order-main-content .order-main-box .total-price-box .box-total {
  position: relative;
  max-width: 535px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.order-page .total-price-box .box-total p {
  margin-left: 20px;
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #000000;
}

.order-page .total-price-box .box-total .data-compl {
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #000000;
}